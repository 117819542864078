import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, CardHeader, Col, Container, Row } from "reactstrap";
import confirm from "reactstrap-confirm";
import FilesImage from "../../../components/FilesImage/FilesImage";
import IdeaPageFilter from "../../../components/IdeaPageFilter/IdeaPageFilter";
import { API_URLS, uri } from "../../../constants/API_URLS";
import { getAICMinibusinesses } from "../../../store/slices/AICMinibusiness/AICMinibusinessActions";
import { getareaSections } from "../../../store/slices/areaSection/areaSectionActions";
import { getBusinessUnits } from "../../../store/slices/factoryMinibusinessUnit/factoryMinibusinessUnitActions";
import {
  deleteIdea,
  getFilteredIdeas,
  getIdeas,
} from "../../../store/slices/idea/ideaActions";
import {
  ideaTableColumns,
  ideaTableColumnsShowLess,
  userTypes
} from "../../../utils/Data";
import {
  deleteZeroProps,
  downloadAsFile,
  exportExcelFile,
  flattenObject,
  getRelicationColor,
  getStatusColor,
  getUserType,
  reformatFilterObject,
  resetFilterFromLocalStorage,
  updateFilterObject
} from "../../../utils/helpers";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const userType = getUserType();
const allowedUserTypes = [
  userTypes.admin,
  userTypes.consultant,
  userTypes.keyOwner,
  userTypes.projectTeam,
  userTypes.keyLeader,
  userTypes.hr,
];
const Idea = () => {
  const filterObject = localStorage.getItem('filterObject');
  const filter = useMemo(() => filterObject ? reformatFilterObject(JSON.parse(filterObject)) : {}, [filterObject]);
  const [isNewIdea, setIsNewIdea] = useState(false)
  const history = useHistory();
  const isExpanded = new URLSearchParams(window.location.search).get('isExpanded') === 'true';
  const { t, i18n } = useTranslation();
  const ideas = useSelector((state) => state.idea.ideas);
  const factoryBusinessUnits = useSelector(
    (state) => state?.factoryBusinessUnit?.businessUnits
  ) || [];
  const tableColumns = isExpanded ? ideaTableColumns(i18n.language, t) : ideaTableColumnsShowLess(i18n.language, t);
  const areaSections = useSelector((state) => state?.areaSection?.areaSections) || [];
  const [selectedIdeaFilter, setSelectedIdeaFilter] = useState({});
  const updateFilteredData = (selectIdeaFilter) => {
    const newSelectedFilter = {
      ...JSON.parse(JSON.stringify(selectedIdeaFilter)),
      ...selectIdeaFilter,
    };
    localStorage.setItem('filterObject', JSON.stringify(newSelectedFilter));
    setSelectedIdeaFilter(newSelectedFilter);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("stayInPage", false);
    dispatch(getBusinessUnits());
    dispatch(getareaSections());
    dispatch(getAICMinibusinesses());
  }, []);

  useEffect(() => {
    if (Object.keys(filter).length > 0) {
      dispatch(getFilteredIdeas(filter));
    } else {
      dispatch(getIdeas());
    }
  }, [dispatch, filter]);
  const deleteOne = async (id) => {
    let result = await confirm({
      title: <>Warning</>,
      message: t("Are you sure you want to delete this?"),
      confirmText: "Confirm",
      confirmColor: "primary",
      cancelColor: "link text-danger",
    });
    if (result) dispatch(deleteIdea(id));
  };
  const filterBtnHandler = () => {
    let filterObject = {};
    if (isNewIdea) {
      filterObject['status.evaluated.value'] = false;
    } else {
      delete filterObject['status.evaluated.value'];
    }
    for (let prop in selectedIdeaFilter) {
      if (prop !== "replication" && prop !== "status" && prop !== 'createdAt') {
        filterObject[prop] = {
          $in: selectedIdeaFilter[prop].map((el) => el.value),
        };
      }
      if (prop === "replication") {
        filterObject["status.replicated.type"] = {
          $in: selectedIdeaFilter[prop].map((el) => el.value),
        };
      }
      if (prop === "status") {
        selectedIdeaFilter[prop]
          .map((el) => el.value)
          .forEach((item) => {
            filterObject[`status.${item}.value`] = true;
          });
      }
      if (prop === 'createdAt') {
        filterObject[prop] = {
          ...selectedIdeaFilter[prop],
        };
      }
      deleteZeroProps(filterObject);
    }
    const keysToUpdate = ['factoryBusinessUnit', 'areaSection', 'AICMinibusiness'];
    updateFilterObject(filterObject, keysToUpdate);

    if (Object.keys(filterObject).length)
      dispatch(getFilteredIdeas(filterObject));
  };
  const toggleExpand = () => {
    if (!isExpanded) {
      history.push('/admin/idea?isExpanded=true');
      window.location.reload();
    } else {
      history.push('/admin/idea?isExpanded=false');
      window.location.reload();
    }
  };
  const onExportBtnClick = () => {
    if (ideas.length) {
      const formattedExportedIdeas = ideas.map(idea => {
        const flattenedObj = flattenObject(idea.status);
        return {
          'Idea Id': idea?._id,
          'Idea Presenter Type': idea?.ideaPresenterType,
          'Factory / Business Unit': idea?.factoryBusinessUnit?.name?.en,
          'Area / Section': idea?.areaSection?.name?.en,
          'Idea Name / Title': idea?.ideaText,
          'Idea Description': idea?.ideaDesc,
          'Empoloyee Id': idea?.employeeCode,
          'Employee Name': idea?.employeeName,
          'Empoloyee Phone Number': idea?.employeePhone,
          'Impact On PQCDSM': idea?.effectOnPQCDSM?.join(),
          'Types Of Savings': idea?.typesOfSavings?.join(),
          'ROI': idea?.status?.implemented?.roi || 0,
          'Reward': idea?.status?.rewarded?.savings || 0,
          "Approval initiated": flattenedObj?.['approved.value'] ? 'Yes' : 'No',
          "Approval decision": flattenedObj?.['approved.accepted'] === 'hold' ? 'Hold' : flattenedObj?.['approved.accepted'] ? 'Yes' : 'No',
          "Estimated savings": flattenedObj?.['approved.estimatedSavings'],
          "Approval reason of hold": flattenedObj?.['approved.reasonOfHold'],
          "Approval reason of denial": flattenedObj?.['approved.reasonOfDenial'],
          "Did stakeholders approve?": flattenedObj?.['approved.questions.Did stakeholders approve?'] ? 'Yes' : 'No',
          "Will have impact?": flattenedObj?.['approved.questions.Will have impact?'] ? 'Yes' : 'No',
          "Will not negatively affect quality/operator/safety/machine?": flattenedObj?.['approved.questions.Will not negatively affect quality/operator/saftey/machine?'] ? 'Yes' : 'No',
          "Evaluation initiated": flattenedObj?.['evaluated.value'] ? 'Yes' : 'No',
          "Evaluation decision": flattenedObj?.['evaluated.accepted'] === 'hold' ? 'Hold' : flattenedObj?.['evaluated.accepted'] ? 'Yes' : 'No',
          "Can it be implemented within 3 weeks?": flattenedObj?.['evaluated.questions.canItBeImplementedWithin3Weeks'] ? 'Yes' : 'No',
          "Can we implement it ourselves?": flattenedObj?.['evaluated.questions.canWeImplementItOurselves'] ? 'Yes' : 'No',
          "Due date": flattenedObj?.['evaluated.questions.dueDate'],
          "Is it low cost to implement?": flattenedObj?.['evaluated.questions.isItCheap'] ? 'Yes' : 'No',
          "Is it easy to implement?": flattenedObj?.['evaluated.questions.isItEasyToImplement'] ? 'Yes' : 'No',
          "Is it one of our top 3 favorite suggestions?": flattenedObj?.['evaluated.questions.isItOneOfOurTop3FavSuggestions'] ? 'Yes' : 'No',
          "Is it urgent?": flattenedObj?.['evaluated.questions.isItUrgent'] ? 'Yes' : 'No',
          "Priority": flattenedObj?.['evaluated.questions.priority'],
          "Responsible person": flattenedObj?.['evaluated.questions.responsiblePerson'],
          "Total ticks": flattenedObj?.['evaluated.questions.totalTicks'],
          "Will it save a significant amount?": flattenedObj?.['evaluated.questions.willItSaveAsignificantAmout'] ? 'Yes' : 'No',
          "Evaluated by": flattenedObj?.['evaluated.evaluatedBy'],
          "Evaluation reason of hold": flattenedObj?.['evaluated.reasonOfHold'],
          "Evaluation reason of denial": flattenedObj?.['evaluated.reasonOfDenial'],
          "Implementation initiated": flattenedObj?.['implemented.value'] ? 'Yes' : 'No',
          "Implementation decision": flattenedObj?.['implemented.accepted'] === 'hold' ? 'Hold' : flattenedObj?.['implemented.accepted'] ? 'Yes' : 'No',
          "Image before": flattenedObj?.['implemented.before._id'] ? `${uri}/file/${flattenedObj?.['implemented.before._id']}` : '',
          "Image after": flattenedObj?.['implemented.after._id'] ? `${uri}/file/${flattenedObj?.['implemented.after._id']}` : '',
          "Equipment name": flattenedObj?.['implemented.equipmentName'],
          "Savings": flattenedObj?.['implemented.savings'],
          "Single-point lesson": flattenedObj?.['implemented.singlePointLesson'],
          "OPL description": flattenedObj?.['implemented.oplDescription'],
          "Reason of hold": flattenedObj?.['implemented.reasonOfHold'],
          "Implementation idea text": flattenedObj?.['implemented.ideaText'],
          "Replicatation type": flattenedObj?.['replicated.type'],
          "Replicatation decision": flattenedObj?.['replicated.value'] ? 'Yes' : 'No',
          "Reward initiated": flattenedObj?.['rewarded.value'] ? 'Yes' : 'No',
          "Reward decision": flattenedObj?.['rewarded.accepted'] === 'hold' ? 'Hold' : flattenedObj?.['rewarded.accepted'] ? 'Yes' : 'No',
          "Event date ": flattenedObj?.['rewarded.eventDate'],
          "Event name ": flattenedObj?.['rewarded.eventName'],
          "Redward savings ": flattenedObj?.['rewarded.savings'],
          "Reward reason of hold ": flattenedObj?.['rewarded.reasonOfHold'],
        }
      });
      exportExcelFile(formattedExportedIdeas, 'Ideas');
    }
  }
  return (
    <>
      <SimpleHeader name={t("idea.ideas")} parentName={t("idea.ideasManagement")} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">{t("idea.ideas")}</h3>
              </CardHeader>
              <ToolkitProvider
                data={ideas}
                keyField="#"
                columns={[
                  ...tableColumns,
                  {
                    dataField: "status",
                    text: t("idea.status"),
                    formatter: (cell, row) => {
                      if (cell)
                        return (
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                          }}>
                            {Object.entries(cell).map((el) => {
                              {
                                if (el[0] !== "filesRelated" && el[0] !== "operatorImg") {
                                  return (
                                    <Button
                                      key={el[0]}
                                      size="sm"
                                      type="button"
                                      style={{ width: '160px', margin: 0 }}
                                      color={el[0] === "replicated" ? getRelicationColor(el[1].type) : getStatusColor(el[1]?.accepted)}
                                    >
                                      <span className>
                                        {t(`idea.${el[0] === "replicated" ? el[1]?.type || "Replicated" : el[0]}`)}
                                      </span>
                                    </Button>
                                  )
                                }
                              }
                            })}
                            {/* can be replicated */}
                            {
                              <Button
                                size="sm"
                                type="button"
                                style={{ width: '160px', margin: 0 }}
                                color={
                                  cell.approved?.canBeReplicated ||
                                    cell.evaluated?.canBeReplicated
                                    ? "success"
                                    : "warning"
                                }
                              >
                                <span>
                                  {t('idea.canBeReplicated')}
                                </span>
                              </Button>
                            }
                          </div>
                        );
                    },
                  },
                  {
                    dataField: "filesRelated",
                    text: t('idea.filesRelated'),
                    formatter: (cell) => {
                      if (cell[0]?._id) {
                        return cell.map(el => <FilesImage key={el?._id} path={el?._id} alt="Files Related" />)
                      }
                    },
                  },
                  {
                    dataField: "status",
                    text: t("idea.imageBefore"),
                    formatter: (cell) => cell?.implemented?.before?._id && <FilesImage path={cell?.implemented?.before?._id} alt="Image Before" />,
                  },
                  {
                    dataField: "status",
                    text: t("idea.imageAfter"),
                    formatter: (cell) => cell?.implemented?.after?._id && <FilesImage path={cell?.implemented?.after?._id} alt="Image After" />,
                  },
                  //Edit Idea
                  allowedUserTypes.slice(0, -2).includes(userType) && {
                    dataField: "_id",
                    text: t("idea.editIdea"),
                    formatter: (cell, row) => (
                      <>
                        <Link
                          to={{
                            pathname: "/admin/editIdeaFields/" + cell,
                            state: { data: row },
                          }}
                          key={cell}
                          className="text-primary"
                        >
                          {t("idea.editIdea")}
                        </Link>
                      </>
                    ),
                  },
                  //Edit Status
                  allowedUserTypes.includes(userType) && {
                    dataField: "_id",
                    text: t("idea.editStatus"),
                    formatter: (cell, row) => (
                      <Link
                        to={{
                          pathname: "/admin/editIdea/" + cell,
                          state: {
                            status: row.status,
                            PQCDSM: row.PQCDSM,
                            ideaDesc: row.ideaDesc
                          },
                        }}
                        key={cell}
                        className="text-primary"
                      >
                        {t("idea.editStatus")}
                      </Link>
                    ),
                  },
                  //Edit Knowledge Table
                  allowedUserTypes.slice(0, -2).includes(userType) && {
                    dataField: "_id",
                    text: t("idea.editOPLtable"),
                    formatter: (cell, row) => (
                      <>
                        {row.status?.implemented?.value ? <Link
                          to={{
                            pathname: "/admin/editOPLtable/" + cell,
                            state: {
                              data: row.status?.implemented?.oplDescription,
                              stateIdeaText: row.status?.implemented?.ideaText
                            },
                          }}
                          key={cell}
                          className="text-primary"
                        >
                          {t("idea.editOPLtable")}
                        </Link> : <span>This idea is not implemented yet</span>
                        }
                      </>
                    ),
                  },
                  //Delete
                  allowedUserTypes.slice(0, -2).includes(userType) && {
                    dataField: "_id",
                    text: t("idea.delete"),
                    formatter: (cell, row) => (
                      <span
                        className="table-action table-action-delete"
                        id={cell}
                        onClick={() => deleteOne(cell)}
                      >
                        <i className="fas fa-trash" />
                      </span>
                    ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <div className="d-flex flex-row align-items-center pb-2" style={{ gap: '2rem' }}>
                        <SearchBar
                          className="form-control"
                          placeholder=""
                          {...props.searchProps}
                        />
                        <span className="mb-2">
                          <p className="h3">{t('ideas.viewNewIdeas')}</p>
                          <label className="custom-toggle custom-toggle-default">
                            <input
                              className='d-none'
                              type="checkbox"
                              name="status.evaluated.value"
                              onChange={(e) => setIsNewIdea(e.target.checked)}
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </span>
                      </div>
                      <IdeaPageFilter
                        areaSections={areaSections}
                        factoryBusinessUnits={factoryBusinessUnits}
                        selectedIdeaFilter={selectedIdeaFilter}
                        updateFilteredData={updateFilteredData}
                        isNewTask={isNewIdea}
                      />
                      <div>
                        <Button
                          color="primary"
                          type="button"
                          style={{ width: "150px", marginBottom: "10px" }}
                          onClick={filterBtnHandler}
                        >
                          {t('idea.filter')}
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          style={{ width: "150px", marginBottom: "10px" }}
                          onClick={toggleExpand}
                        >
                          {isExpanded ? t('idea.Show Less') : t('idea.Show More')}
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          style={{ width: "180px", marginBottom: "10px" }}
                          onClick={() => {
                            downloadAsFile(API_URLS.Idea.CRUD.backup, 'Ideas')
                          }}
                        >
                          {t('idea.downloadIdeasBackup')}
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          style={{ width: "180px", marginBottom: "10px" }}
                          onClick={() => {
                            resetFilterFromLocalStorage();
                            window.location.reload();
                          }}
                        >
                          {t('idea.resetFilter')}
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          style={{ width: "150px", marginBottom: "10px" }}
                          onClick={onExportBtnClick}
                        >
                          {t('idea.exportIdeas')}
                        </Button>
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      hover={true}
                      tabIndexCell={true}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      keyField="index"
                      data={ideas}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div >
        </Row >
      </Container >
    </>
  );
};
export default Idea;
